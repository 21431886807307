import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Link from 'gatsby-link';
import { useQueryParam } from 'use-query-params';
import {
  ShopLayout,
  Row,
  StyledSectionHeader,
  StyledDisclaimerText,
} from '../components/Layout';
import MerchantLayout from '../components/Layout/MerchantLayout';
import StyledSmile from '../components/StyledSmile';
import StyledHeart from '../components/StyledHeart';
import { apiCreate } from '../services/sendToApi';
import merchants from '../merchants.json';

const GrowRow = styled(Row)`
  flex: 1;
  margin: 0px;
  padding: 10px;
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

function IndexPage() {
  const { t } = useTranslation();
  const [shop] = useQueryParam('shop');
  const [merchant] = useQueryParam('merchant');
  const [id, setId] = useState();

  const state = { shop, merchant };

  // Check we have either a shop or a *valid* merchant - we don't have a list of valid shops here
  const hasShop = shop !== undefined;
  const hasMerchant = merchant !== undefined;
  const isValidMerchant = merchants[merchant] !== undefined;
  const isValidRequest = hasShop || (hasMerchant && isValidMerchant);

  if (!isValidRequest) {
    if (typeof window !== 'undefined') {
      window.location.replace('https://www.gokarla.io/');
    }

    return (
      <div>
        You are being redirected to&nbsp;
        <a href="https://www.gokarla.io">https://www.gokarla.io</a>
      </div>
    );
  }

  useEffect(() => {
    if (shop || merchant) {
      apiCreate(state).then((response) => response.json()).then((json) => {
        setId(json.id);
      });
    }
  }, []);

  if (id === undefined) {
    return <div>loading...</div>;
  }

  if (hasShop) {
    return (
      <ShopLayout>
        <Row>
          <StyledSectionHeader>
            {t('indexPage.question')}
          </StyledSectionHeader>
        </Row>

        <Row>
          <StyledDisclaimerText>
            {t('indexPage.reactionDisclaimer')}
          </StyledDisclaimerText>
        </Row>
        <GrowRow>
          <StyledLink to={id ? '/happy' : null} state={{ shop, id, mood: 'happy' }}>
            <StyledSmile />
          </StyledLink>
        </GrowRow>
        <GrowRow>
          <StyledLink to={id ? '/sad' : null} state={{ shop, id, mood: 'sad' }}>
            <StyledHeart />
          </StyledLink>
        </GrowRow>
      </ShopLayout>
    );
  }

  return (
    <MerchantLayout merchant={merchant}>
      <Row>
        <StyledSectionHeader>
          {t('merchant.index.question')}
        </StyledSectionHeader>
      </Row>

      <Row>
        <StyledDisclaimerText>
          {t('indexPage.reactionDisclaimer')}
        </StyledDisclaimerText>
      </Row>
      <GrowRow>
        <StyledLink to={id ? '/merchant/happy' : null} state={{ merchant, id, mood: 'happy' }}>
          <StyledSmile />
        </StyledLink>
      </GrowRow>
      <GrowRow>
        <StyledLink to={id ? '/merchant/sad' : null} state={{ merchant, id, mood: 'sad' }}>
          <StyledHeart />
        </StyledLink>
      </GrowRow>
    </MerchantLayout>
  );
}

export default IndexPage;
