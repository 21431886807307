import styled from 'styled-components';
import KarlaSmile from '../images/karla_smile.svg';

const StyledSmile = styled(KarlaSmile)`
  margin: 0px 4px;
  width: 80%;
  max-width: 280px;
  max-height: calc(50vh - 150px);
  transition: all 0.3s ease-in;
  display: ${(props) => (props.hide ? 'none' : 'block')};
  cursor: pointer;
`;

export default StyledSmile;
